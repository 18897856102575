<template>
  <div class="text-toggle-container">
    <div class="text-toggle" :style="{ width: width + 'px' }">
      <div class="indicator" :class="{ toLeft: leftActive }"></div>
      <div
        class="left"
        :class="{ active: leftActive }"
        @click.stop="toggle(true)"
      >
        {{ leftValue }}
      </div>
      <div
        class="right"
        :class="{ active: !leftActive }"
        @click.stop="toggle(false)"
      >
        {{ rightValue }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TjingTextOptionToggle",
  props: ["width", "leftValue", "rightValue"],
  data() {
    return {
      leftActive: true,
    };
  },
  computed: {},
  methods: {
    toggle(bool) {
      if (bool == true && bool !== this.leftActive) {
        this.leftActive = true;
        this.$emit("left");
      } else if (bool == false) {
        this.leftActive = false;
        this.$emit("right");
      }
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";
.text-toggle {
  background: $midnight;
  display: flex;
  align-items: center;
  color: white;
  position: relative;
  border-radius: 6px;
  position: relative;
  height: 28px;

  .indicator {
    width: calc(50% - 2px);
    background: #fff;
    position: absolute;
    border-radius: 4px;
    z-index: 1;
    right: 0px;
    top: 2px;
    bottom: 2px;
    transform: translateX(-2px);
    transition: all ease 0.3s;

    &.toLeft {
      transform: translateX(calc(-100% - 2px));
    }
  }
  .left,
  .right {
    @include Gilroy-Bold;
    font-size: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 2;
    cursor: pointer;
    transition: all ease 0.3s;
    user-select: none;

    &.active {
      cursor: default;
      color: $midnight;
    }
  }

  .left {
    margin-left: 2px;
  }
  .right {
    margin-right: 2px;
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
}
</style>
